<template>
  <v-navigation-drawer
    :value="isSidebarSendCotizationActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-send-cotization-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.sendCotization') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-send-cotization-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              v-if="clientCarShop.email"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.email"
                outlined
                :label="$t('lbl.para')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <fragment v-else-if="clientCarShop.emails.length > 0">
              <v-col
                v-for="(ema, indEma) in clientCarShop.emails"
                :key="indEma"
                cols="12"
                md="12"
              >
                <v-text-field
                  :value="ema"
                  disabled
                  outlined
                  :label="$t('lbl.para')"
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </fragment>
            <v-col
              v-else
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.email"
                outlined
                :label="$t('lbl.para')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.subject"
                outlined
                :label="$t('lbl.subject')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <tiptap-vuetify
                v-model="clientCarShop.message"
                :extensions="extensions"
                :placeholder="`${$t('lbl.message')}...`"
              />
              <!--<v-textarea
                v-model="clientCarShop.message"
                outlined
                :label="$t('lbl.message')"
                rows="11"
                hide-details="auto"
                class="mb-6"
              >
              </v-textarea>-->
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <v-chip
                color="primary"
                small
                label
                class="v-chip-light-bg primary--text mb-6 mt-2"
              >
                {{ $t('lbl.adjuntCotization') }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <!--@click="$emit('update:is-sidebar-send-cotization-active', false)"-->
              <v-btn
                color="error"
                block
                @click="$emit('showReturnDataClient')"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loadingBtn"
                @click="$emit('confirmSendCotization')"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  // ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  model: {
    prop: 'isSidebarSendCotizationActive',
    event: 'update:is-sidebar-send-cotization-active',
  },
  props: {
    isSidebarSendCotizationActive: {
      type: Boolean,
      required: true,
    },
    nameCompany: {
      type: String,
      required: true,
    },
    nameUser: {
      type: String,
      required: true,
    },
    nameB2b: {
      type: String,
      required: true,
    },
    emailUser: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      extensions: [
        // BulletList,
        // OrderedList,
        // ListItem,
        Italic,
        Bold,
        HardBreak,
      ],
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
    }),
    message: {
      get() {
        const msg = `<p>Estimado cliente,</p>
        <p>
          Gracias por confiar en ${this.user.nivel <= 0 ? this.nameB2b : this.nameCompany}. Estamos encantados con organizar su próximo viaje.
        </p>
        <p>
          Hemos generado una cotización, válida por 72 horas, de acuerdo con los servicios que nos ha solicitado.
        </p>
        <p>
          Tenga en cuenta que esta cotización sólo constituye una referencia de los servicios
          solicitados y estará sujeta a confirmación en el momento de la solicitud de su
          reserva; por lo que la disponibilidad y los precios pueden variar.
        </p>
        <p>
          ${this.user.nivel <= 0 ? this.nameB2b : this.nameCompany} no podrá garantizar los servicios ni los precios que se
          muestran en esta cotización hasta que la reserva no sea confirmada con nuestros
          proveedores.
        </p>
        <p>
          Atentamente,<br />
          ${this.nameUser}<br />
          ${this.emailUser}<br />
          ${this.user.nivel <= 0 ? this.nameB2b : this.nameCompany}
        </p>
`

        return msg
      },
    },
  },

  created() {
    this.clientCarShop.subject = this.user.nivel <= 0 ? `${this.nameB2b} - Cotización de viaje` : `${this.nameCompany} - Cotización de viaje`
    this.clientCarShop.message = this.message
  },
  methods: {
    ...mapMutations(['updateMessageClientCarShop']),
  },
}
</script>
