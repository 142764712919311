<template>
  <fragment v-if="!isLoading">
    <v-row class="app-invoice-preview">
      <v-col
        cols="12"
        md="9"
      >
        <Pdf
          :config="config"
          :model="model"
          :email-principal="emailPrincipal"
          :cars-items="carsItems"
          :price-all-cars="priceAllCars"
          :price-all-reserve="priceAllReserve"
          :currencies="currencies"
        />
      </v-col>

      <!-- Right Column: Invoice Action -->
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-card-text>
            <v-btn
              color="primary"
              class="mb-3"
              block
              @click="sendPdf"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiSendOutline }}
              </v-icon>
              <span>Enviar Cotización</span>
            </v-btn>
            <v-btn
              class="mb-3"
              color="secondary"
              block
              outlined
              :loading="loadingDescargar"
              @click="downloadPdf"
            >
              Descargar Cotización
            </v-btn>
            <v-btn
              class="mb-3"
              color="secondary"
              block
              outlined
              :loading="loadingImprimir"
              @click="printPdf"
            >
              Imprimir Cotización
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SidebarSendCotization
      v-if="isSidebarSendCotizationActive"
      v-model="isSidebarSendCotizationActive"
      :name-company="nameCompany"
      :name-user="nameUser"
      :name-b2b="nameB2b"
      :email-user="emailUser"
      :user="user"
      @confirmSendCotization="confirmSendCotization"
      @showReturnDataClient="showReturnDataClient"
    />
  </fragment>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapMutations } from 'vuex'

import {
  mdiChevronLeft,
  mdiBagCarryOn,
  mdiBagCarryOnOff,
  mdiBagPersonal,
  mdiBagPersonalOff,
  mdiArrowRight,
  mdiSwapHorizontal,
} from '@mdi/js'

// eslint-disable-next-line import/order, import/no-unresolved
import SidebarSendCotization from '@/views/booking/cotizador/selected/utils/SidebarSendCotization.vue'
// eslint-disable-next-line import/no-unresolved
import Pdf from '@/views/utils/viewPdf/utils/Pdf.vue'

export default {
  components: {
    SidebarSendCotization,
    Pdf,
  },
  data() {
    return {
      model: {},
      nameB2b: null,
      nameCompany: '',
      nameUser: '',
      emailUser: '',
      btnName: '',
      ctasBancarias: [],
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
      config: {
        logo: null,
        name: '',
      },
      priceAllCars: 0,
      priceAllReserve: 0,
      emailPrincipal: null,
      loadingDescargar: false,
      loadingImprimir: false,
      loadingEnviar: false,
      loadingCond: true,
      conditions: [],
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      emailSoporte: process.env.VUE_APP_SUPORT,
      phoneSoporte: process.env.VUE_APP_PHONE_SUPORT,
      isLoading: true,
      isSidebarSendActive: false,
      loadingPdf: false,
      loadingEmail: false,
      client: {},
      listItems: [],
      destinos: [],
      dimensiones: [],
      pesos: [],
      icons: {
        mdiChevronLeft,
        mdiBagCarryOn,
        mdiBagCarryOnOff,
        mdiBagPersonal,
        mdiBagPersonalOff,
        mdiArrowRight,
        mdiSwapHorizontal,
      },
      carsItems: [],
      user: {},
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      code: null,
      currencies: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      carItems: state => state.app.carItems,
      clientCarShop: state => state.app.clientCarShop,
    }),
  },
  mounted() {
    if (sessionStorage.getItem('code-cotiza') === null) {
      this.$router.push({ name: 'cotizador' })
    } else {
      this.code = sessionStorage.getItem('code-cotiza')
      this.getCurrencies()
    }
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations(['clearCotizadorFlightFavorite', 'setSearchingFlightCotizador', 'updateLoadingBtn']),
    getCurrencies() {
      this.axios
        .get('currency_list?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.currencies = res.data.data
          }
        })
        .finally(() => {
          this.getConfig()
        })
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          this.user = response.data.data
        })
        .catch(error => console.log(error))
    },
    getConfig() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            if (this.config.emails_noty_reserve) {
              this.config.emails_noty_reserve.forEach(element => {
                if (element.principal) {
                  this.emailPrincipal = element.email
                }
              })
            }
            this.nameB2b = this.config.name
            if (this.config.ctas_bancarias) {
              this.ctasBancarias = this.config.ctas_bancarias
            }

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.load()
        })
    },
    load() {
      this.axios
        .post('view-pdf/cotization', { code: this.code })
        .then(res => {
          if (res.data.success) {
            this.model = res.data.data.data

            // this.conditions = this.model.conditions
            this.model.car_shop.forEach(element => {
              if (element.entity === 'cars') {
                this.carsItems.push(element)
                this.priceAllCars += element.data_markups.priceTotal
                this.priceAllReserve += element.data_markups.priceTotal
              } else if (element.entity === 'hotels') {
                this.carsItems.push(element)
                this.priceAllCars += element.data_markups.priceTotal
                this.priceAllReserve += element.data_markups.priceTotal
              }
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          if (sessionStorage.getItem('action-cotiza') === 'print') {
            setTimeout(() => {
              this.printPdf()
            }, 200)
          }
        })
    },
    showReturnDataClient() {
      setTimeout(() => {
        this.isSidebarDataClientActive = true
        this.isSidebarSendCotizationActive = false
      }, 50)
    },
    sendPdf() {
      this.isSidebarSendCotizationActive = true
    },
    confirmSendCotization() {
      const json = {
        subject: this.clientCarShop.subject,
        message: this.clientCarShop.message,
        email: this.clientCarShop.email,
        data_client: this.clientCarShop,
        action: 'send',
        codeReserva: this.model.code,
      }
      this.updateLoadingBtn(true)
      this.axios
        .post('reservations/send-email-adjunt', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.msgSendEmail', { email: this.clientCarShop.email }))
            this.isSidebarSendCotizationActive = false
          }
        })
        .finally(() => {
          this.updateLoadingBtn(false)
        })
    },
    downloadPdf() {
      this.loadingDescargar = true
      const json = {
        action: 'download',
        codeReserva: this.code,
      }
      this.axios
        .post('reservations/send-email-adjunt', json, { responseType: 'arraybuffer' })
        .then(res => {
          // console.log(res)
          const filename = this.code

          this.downloadFile(res, filename)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingDescargar = false
        })
    },
    printPdf() {
      // this.loadingImprimir = true
      window.print()
    },

    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }
  .no-printme {
    display: none;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}

.containerSizeMine {
  height: max-content;
  bottom: 10px;
}

.posMine {
  position: absolute;
  bottom: 20px;
}

.posFlexMine {
  display: inline-flex;
}

.posBlockMine {
  display: inline-block;
}

.marginGenMine {
  margin: 10px 50px;
}

.marginRightMine {
  margin-right: 20px;
}

.marginRightMine50px {
  margin-right: 50px;
}

.font {
  font-family: Inter, sans-serif;
  line-height: 1.5;
  margin: 0;
}

.h2 {
  font-size: 24px;
  font-weight: bold;
}

.h3 {
  font-size: 20px;
  font-weight: bold;
}

.blackTextMine {
  color: black;
}

.grayTextMine {
  color: rgba(94, 86, 105, 0.68) !important;
}

.h1 {
  font-size: 30px;
  font-weight: bold;
}

.Mine10px {
  font-size: 10px;
}

.Mine14px {
  font-size: 12px;
}

.Mine18px {
  font-size: 14px;
}

.bold {
  font-weight: bolder;
}

.marginMine {
  margin: 0px;
  margin-bottom: 10px !important;
}

.secWidthMine {
  width: auto;
}

.secWidthMine2 {
  width: 180px;
}

.cardBackMine {
  background-color: #eceef4;
  padding: 20px;
}

.cardBackMine2 {
  background-color: #fae49a;
  padding: 20px;
}
</style>
